/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/fonts.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    img {
        height: 100%;
    }
}

:root {
    --ion-font-family: "Work Sans", sans-serif;
}

// Hide scrollbar for Chrome, Safari and Opera
ion-content::part(scroll)::-webkit-scrollbar {
    display: none;
}

// Hide scrollbar for IE, Edge and Firefox
ion-content::part(scroll) {
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
}

.no-scroll {
    width: calc(100% + 16px);
}

.no-scroll::part(scroll) {
    @apply pr-4;
}

:focus {
    outline: 0 !important;
    --ripple-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: currentColor !important;
    transition: background-color 5000s ease-in-out 0s;
}

ion-button {
    --box-shadow: none;
    --border-radius: 16px;
    @apply text-h4-m font-medium h-auto normal-case tracking-normal;
    --border-width: 2px;
    --border-style: solid;
    --border-color: transparent;

    @screen lg {
        @apply text-buttonDesktop;
    }

    &::part(native) {
        @apply px-5 py-4;
    }

    &.button-small {
        --border-radius: 30px;
        @apply text-buttonSmall;

        &::part(native) {
            @apply px-3 py-2;
        }
    }
}

.input-style {
    @apply mt-2;
}

.button-primary-interaction {
    --transition: none;
    --background-hover: var(--violet-60);
    --background-hover-opacity: 40%;
    --background-activated: var(--violet-60);
    --background-activated-opacity: 50%;
    --background-focused: var(--violet-120);
    --background-focused-opacity: 50%;
}

.button-primary-interaction:active {
    --border-color: var(--violet-80);
    --border-width: 2px;
    --border-style: solid;
}

.button-primary-interaction:focus {
    --background: var(--violet-120);
    --background-focused: var(--violet-120);
    --border-color: var(--violet-60);
    --border-width: 2px;
    --border-style: solid;
}

.button-secondary-interaction {
    --transition: none;
    --background-hover: var(--aquamarin);
    --background-activated: var(--aquamarin-60);
    --color-activated: var(--petrol-100);
    --background-focused: var(--aquamarin-60);
}

.button-secondary-interaction:active {
    --border-color: var(--aquamarn-80);
    --border-width: 2px;
    --border-style: solid;
    --color: var(--petrol-100);
}

.button-secondary-interaction:focus-within {
    --background: var(--petrol-80);
    --border-color: var(--aquamarin-80);
    --border-width: 2px;
    --border-style: solid;
    --color: var(--petrol-100);
}

.sheet-modal {
    --width: 100%;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    --border-radius: 36px 36px 0px 0px;
    --margin-top: 20px;
}

ion-header {
    &::after {
        @apply hidden
    }
}

.modal-wrapper {
    &.modal-handle {
        @apply mt-4;
    }
}

.web-modal {
    & {
        @apply flex items-center justify-center h-full;
    }

    &::part(content) {
        --max-width: 335px;
        -webkit-transform: none !important;
        transform: none !important;
        @apply relative flex items-center justify-center h-auto rounded-3xl pt-5 pb-10 px-5 m-0;

        @screen md {
            --max-width: 480px;
        }

        @screen lg {
            --max-width: 500px;
        }
    }

    &::part(handle) {
        @apply hidden;
    }

    &.auto-height {
        &.bottom {
            @apply items-end;
        }

        --height: auto;

        .ion-page {
            contain: content;
            @apply relative block;

            .inner-content {
                max-height: 80vh;
                @apply overflow-auto p-2.5;
            }
        }
    }

    ion-content {
        --background: var(--petrol-5);
    }
}

@screen sm {
    .web-modal {
        &::part(content) {
            @apply p-5 my-0 mx-5;
        }
    }
}

ion-content {
    --background: var(--petrol-5);
}

.sheet-modal {
    &::part(content) {
        --border-radius: 32px;
        --max-width: calc(100% - 32px);
        @apply h-auto;
    }

    &.show-modal {
        @apply flex items-end justify-center h-full;
    }

    &::part(content) {
        --max-width: none;
        -webkit-transform: none !important;
        transform: none !important;
        @apply relative flex items-center justify-center h-auto rounded-t-3xl rounded-b-none p-0 pb-10 m-0;
    }

    &::part(handle) {
        @apply hidden;
    }

    &.auto-height {
        &.bottom {
            @apply items-end;
        }

        --height: auto;

        .ion-page {
            contain: content;
            @apply relative block;

            .inner-content {
                max-height: 80vh;
                @apply overflow-auto p-2.5;
            }
        }
    }
}

.mobility-sheet-modal {
    &::part(content) {
        --max-width: calc(100% - 32px);
        @apply rounded-t-4xl;
    }

    & {
        --width: 100%;
        @apply flex items-end justify-center h-full;
    }

    &::part(content) {
        --max-width: none;
        -webkit-transform: none !important;
        transform: none !important;
        max-height: 50vh;
        @apply relative flex items-center justify-center rounded-t-3xl m-0;

        @screen sm {
            max-height: 75vh;
        }
    }

    &::part(handle) {
        @apply hidden;
    }

    &::part(background) {
        background-color: rgba(0, 0, 0, 0.4)
    }

    &.auto-height {
        &.bottom {
            @apply items-end;
        }

        --height: auto;

        .ion-page {
            contain: content;
            @apply relative block;

            .inner-content {
                max-height: 75%;
                @apply overflow-auto p-2.5;
            }
        }
    }
}

.image-modal {
    & {
        @apply flex items-center justify-center h-full;
    }

    &::part(content) {
        width: calc(100% - 40px);
        --max-width: 1140px;
        -webkit-transform: none !important;
        transform: none !important;
        @apply relative flex items-center justify-center h-min rounded-3xl p-6 mx-5;

        @screen lg {
            @apply m-0;
        }
    }

    &::part(handle) {
        @apply hidden;
    }

    &.auto-height {
        &.bottom {
            @apply items-end;
        }

        --height: auto;

        .ion-page {
            contain: content;
            @apply relative block;

            .inner-content {
                max-height: 80vh;
                @apply overflow-auto p-2.5;
            }
        }
    }

    ion-content {
        --background: var(--petrol-5);
    }
}

ion-toolbar {
    --background: none;
    --border-color: transparent;
    --border-width: 0;
}

ion-loading {
    &.custom-loading {
        --background: transparent;
        --spinner-color: var(--petrol-10);
        color: var(--petrol-10);
    }
}

.loading-wrapper.sc-ion-loading-md {
    @apply shadow-none;
}

ion-spinner {
    @apply w-90px h-90px;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: none !important;
}

.h1 {
    @apply text-h1-m font-medium tracking-void;

    &.h1-web {
        @apply text-h1-m-w;
    }
}

.h2 {
    @apply text-h2-m font-medium tracking-void;

    &.h2-web {
        @apply text-h2-m-w;
    }
}

.h3,
.h3-web {
    @apply text-h3-m font-medium tracking-void;
}

.h4,
.h4-web {
    @apply text-h4-m font-medium tracking-0.005;
}

.h5,
.h5-web {
    @apply text-h5-m font-medium tracking-0.02 uppercase;
}

.h6 {
    @apply text-h6-m font-medium tracking-void;

    &.h6-web {
        @apply text-h6-m-w;
    }
}

.formError {
    @apply text-formError font-normal tracking-void;

    &.formError-web {
        @apply text-formError-w;
    }
}

.Introtext,
.Introtext-web {
    @apply font-normal text-intro tracking-0-02;
}

.CopyStandard,
.CopyStandard-web {
    @apply font-normal text-copyStandard-m tracking-void;
}

.CopySmall,
.CopySmall-web {
    @apply font-normal text-CopySmall tracking-void;
}

.Form_Type,
.Form_Type-web {
    @apply font-medium text-form-m tracking-0.01;
}

.Category_Mobility {
    @apply text-cat-m font-medium tracking-void;

    .Category_Mobility-web {
        @apply text-cat;
    }
}

.Category_slider {
    @apply text-cat-slider-app font-medium tracking-void;

    .Category_slider-web {
        @apply text-cat-slider;
    }
}

@screen md {
    .h1 {
        @apply text-h1-t tracking-void font-medium;

        &.h1-web {
            @apply text-h1-t-w;
        }
    }

    .h2 {
        @apply text-h2-t tracking-void font-medium;

        &.h2-web {
            @apply text-h2-m;
        }
    }

    .h3 {
        @apply text-h3-t font-medium tracking-void;

        &.h3-web {
            @apply text-h3-t-w;
        }
    }

    .h4 {
        @apply text-h4-t font-medium tracking-0.005;

        &.h4-web {
            @apply text-h6-m-w;
        }
    }

    .h5 {
        @apply text-h5-t font-semibold tracking-0.02 uppercase;

        &.h5-web {
            @apply text-h5-t-w;
        }
    }

    .h6 {
        @apply text-h6-t font-medium tracking-void;

        &.h6-web {
            @apply text-h6-t-w;
        }
    }

    .Introtext {
        @apply text-intro-t font-normal tracking-0-02;

        &.Introtext-web {
            @apply text-intro;
        }
    }

    .CopyStandard {
        @apply text-copyStandard-t font-normal tracking-void;

        &.CopyStandard-web {
            @apply text-copyStandard-m;
        }
    }

    .CopySmall {
        @apply text-copySmall-t font-normal tracking-void;

        &.CopySmall-web {
            @apply text-copySmall-t-w;
        }
    }

    .Form_Type {
        @apply font-medium text-form-t tracking-0.01;

        &.Form_Type-web {
            @apply text-form;
        }
    }

    .Category_Mobility {
        @apply text-cat-t font-medium tracking-void;

        .Category_Mobility-web {
            @apply text-cat;
        }
    }
}

@screen lg {
    .h1.h1-web {
        @apply font-medium text-h1 tracking-void;
    }

    .h2.h2-web {
        @apply font-medium text-h2-m tracking-void;
    }

    .h3.h3-web {
        @apply font-medium text-intro tracking-void;
    }

    .h4.h4-web {
        @apply font-medium text-h6-m-w tracking-0.005;
    }

    .h5.h5-web {
        @apply text-h5-t-w font-semibold tracking-0.01 uppercase;
    }

    .h6.h6-web {
        @apply text-h6 font-medium tracking-void;
    }

    .Introtext.Introtext-web {
        @apply text-h6-t font-normal tracking-0-02;
    }

    .CopyStandard.CopyStandard-web {
        @apply font-normal text-copyStandard tracking-void;
    }

    .CopySmall.CopySmall-web {
        @apply font-normal text-copySmall-t-w tracking-void;
    }

    .Form_Type.Form_Type-web {
        @apply text-form font-medium tracking-0.01;
    }

    .Category_Mobility.Category_Mobility {
        @apply text-cat font-medium tracking-void;
    }
}

ion-searchbar {
    --color: var(--violet-120);
    @apply rounded-3.5xl p-0 bg-white text-left w-full;
    --border-radius: 30px;
    --icon-color: #8F66A9;
    --placeholder-color: #634675;
    --box-shadow: none;
    --placeholder-font-weight: 500;
    --placeholder-opacity: 0.75;

    ::ng-deep {
        .searchbar-input-container {
            @apply rounded-3.5xl flex items-center;

            .searchbar-input {
                @apply bg-white;
            }
        }
    }
}

.mobile-searchbar {
    .searchbar-search-icon.sc-ion-searchbar-md {
        @apply w-4.5 h-4.5 top-auto;
    }

    .searchbar-search-icon.sc-ion-searchbar-ios {
        @apply w-4.5 h-4.5 top-auto;
    }

    .searchbar-input.sc-ion-searchbar-ios {
        @apply ml-5 top-auto;
    }

    .searchbar-input.sc-ion-searchbar-md {
        @apply h-14 top-auto;
    }

    .sc-ion-searchbar-ios-h {
        @apply h-14 top-auto;
    }

    @apply h-14;
}

.web-searchbar {
    .searchbar-search-icon.sc-ion-searchbar-md {
        @apply w-4.5 h-4.5 top-auto;
    }

    .searchbar-search-icon.sc-ion-searchbar-ios {
        @apply w-4.5 h-4.5 top-auto;
    }

    .searchbar-input.sc-ion-searchbar-ios {
        @apply ml-5 top-auto;
    }

    @apply h-12;

    .searchbar-clear-icon.sc-ion-searchbar-md {
        @apply block top-auto;
    }
}

.pb-mobile {
    padding-bottom: calc(var(--ion-safe-area-bottom) + 80px);
}

* {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

*::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
    scrollbar-width: none;
}

ion-content {

    &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
}