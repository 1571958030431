@font-face {
    font-family: 'hb-icon';
    src: url('../assets/hbIcon/fonts/hb-icon.eot?1zmsvj');
    src: url('../assets/hbIcon/fonts/hb-icon.eot?1zmsvj#iefix') format('embedded-opentype'), url('../assets/hbIcon/fonts/hb-icon.ttf?1zmsvj') format('truetype'), url('../assets/hbIcon/fonts/hb-icon.woff?1zmsvj') format('woff'), url('../assets/hbIcon/fonts/hb-icon.svg?1zmsvj#hb-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="hb-icon-"],
[class*=" hb-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'hb-icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hb-icon-meeting-participants:before {
    content: "\e961";
}

.hb-icon-meeting-camera-slash:before {
    content: "\e95d";
}

.hb-icon-meeting-gear:before {
    content: "\e95e";
}

.hb-icon-meeting-hand-paper:before {
    content: "\e95f";
}

.hb-icon-meeting-volume-slash:before {
    content: "\e960";
}

.hb-icon-call-decline:before {
    content: "\e955";
}

.hb-icon-meeting-camera:before {
    content: "\e956";
}

.hb-icon-meeting-volume-cross:before {
    content: "\e957";
}

.hb-icon-meeting-volume-high:before {
    content: "\e958";
}

.hb-icon-meeting-volume-low:before {
    content: "\e959";
}

.hb-icon-meeting-volume-mute:before {
    content: "\e95a";
}

.hb-icon-refresh-ccw:before {
    content: "\e95b";
}

.hb-icon-send-2:before {
    content: "\e95c";
}

.hb-icon-check-2:before {
    content: "\e953";
}

.hb-icon-cross-2:before {
    content: "\e954";
}

.hb-icon-alert-circle:before {
    content: "\e946";
}

.hb-icon-cross-circle:before {
    content: "\e947";
}

.hb-icon-info:before {
    content: "\e948";
}

.hb-icon-meeting-mic:before {
    content: "\e949";
}

.hb-icon-meeting-mic-slash:before {
    content: "\e94a";
}

.hb-icon-meeting-pause:before {
    content: "\e94b";
}

.hb-icon-meeting-play:before {
    content: "\e94c";
}

.hb-icon-star:before {
    content: "\e94d";
}

.hb-icon-star-s:before {
    content: "\e94e";
}

.hb-icon-stop-circle:before {
    content: "\e94f";
}

.hb-icon-time:before {
    content: "\e950";
}

.hb-icon-timer:before {
    content: "\e951";
}

.hb-icon-watch:before {
    content: "\e952";
}

.hb-icon-loader:before {
    content: "\e945";
}

.hb-icon-user-square:before {
    content: "\e944";
}

.hb-icon-angle-down:before {
    content: "\e900";
}

.hb-icon-arrow-left:before {
    content: "\e901";
}

.hb-icon-arrow-right:before {
    content: "\e902";
}

.hb-icon-arrows-close:before {
    content: "\e903";
}

.hb-icon-arrows-expand:before {
    content: "\e904";
}

.hb-icon-award:before {
    content: "\e905";
}

.hb-icon-bed-2:before {
    content: "\e906";
}

.hb-icon-bell:before {
    content: "\e907";
}

.hb-icon-book:before {
    content: "\e908";
}

.hb-icon-bookmark:before {
    content: "\e909";
}

.hb-icon-bookqueue:before {
    content: "\e90a";
}

.hb-icon-calendar:before {
    content: "\e90b";
}

.hb-icon-calendar-date:before {
    content: "\e90c";
}

.hb-icon-calendar-event:before {
    content: "\e90d";
}

.hb-icon-calendar-person:before {
    content: "\e90e";
}

.hb-icon-camera:before {
    content: "\e90f";
}

.hb-icon-check:before {
    content: "\e910";
}

.hb-icon-check-circle-r:before {
    content: "\e911";
}

.hb-icon-check-circle-s:before {
    content: "\e912";
}

.hb-icon-comment-2-question:before {
    content: "\e913";
}

.hb-icon-comment-r:before {
    content: "\e914";
}

.hb-icon-comment-s:before {
    content: "\e915";
}

.hb-icon-creditcard:before {
    content: "\e916";
}

.hb-icon-cross:before {
    content: "\e917";
}

.hb-icon-edit-2:before {
    content: "\e918";
}

.hb-icon-eye:before {
    content: "\e919";
}

.hb-icon-eye-slash:before {
    content: "\e91a";
}

.hb-icon-file-check:before {
    content: "\e91b";
}

.hb-icon-file-download:before {
    content: "\e91c";
}

.hb-icon-file-text:before {
    content: "\e91d";
}

.hb-icon-file-upload:before {
    content: "\e91e";
}

.hb-icon-filmboard:before {
    content: "\e91f";
}

.hb-icon-folder-open:before {
    content: "\e920";
}

.hb-icon-folder-upload:before {
    content: "\e921";
}

.hb-icon-forward-15:before {
    content: "\e922";
}

.hb-icon-gear-2:before {
    content: "\e923";
}

.hb-icon-grid-16:before {
    content: "\e924";
}

.hb-icon-heart-r:before {
    content: "\e925";
}

.hb-icon-heart-s:before {
    content: "\e926";
}

.hb-icon-image-upload:before {
    content: "\e927";
}

.hb-icon-link-2:before {
    content: "\e928";
}

.hb-icon-lock-2:before {
    content: "\e929";
}

.hb-icon-lock-2-opened:before {
    content: "\e92a";
}

.hb-icon-lock-opened:before {
    content: "\e92b";
}

.hb-icon-monitor:before {
    content: "\e92c";
}

.hb-icon-more-hor:before {
    content: "\e92d";
}

.hb-icon-more-ver:before {
    content: "\e92e";
}

.hb-icon-pause1:before {
    content: "\e92f";
}

.hb-icon-pentool-2:before {
    content: "\e930";
}

.hb-icon-phone:before {
    content: "\e931";
}

.hb-icon-planet:before {
    content: "\e932";
}

.hb-icon-play1:before {
    content: "\e933";
}

.hb-icon-replay-15:before {
    content: "\e934";
}

.hb-icon-search:before {
    content: "\e935";
}

.hb-icon-send:before {
    content: "\e936";
}

.hb-icon-shield-check:before {
    content: "\e937";
}

.hb-icon-signout:before {
    content: "\e938";
}

.hb-icon-stack:before {
    content: "\e939";
}

.hb-icon-tag:before {
    content: "\e93a";
}

.hb-icon-thermometer:before {
    content: "\e93b";
}

.hb-icon-trash:before {
    content: "\e93c";
}

.hb-icon-tv:before {
    content: "\e93d";
}

.hb-icon-undo:before {
    content: "\e93e";
}

.hb-icon-volume-high:before {
    content: "\e93f";
}

.hb-icon-volume-low:before {
    content: "\e940";
}

.hb-icon-volume-mute:before {
    content: "\e941";
}

.hb-icon-volume-slash:before {
    content: "\e942";
}

.hb-icon-zoom-in:before {
    content: "\e943";
}