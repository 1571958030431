// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
    /** primary **/
    --ion-color-primary: #a472c4;
    --ion-color-primary-rgb: 164, 114, 196;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #BE91DB;
    --ion-color-primary-tint: #634675;
    ;
    /** secondary **/
    --ion-color-secondary: #00768C;
    --ion-color-secondary-rgb: 0, 118, 140;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #5FCFDF;
    --ion-color-secondary-tint: #00768C;
    /** tertiary **/
    --ion-color-tertiary: #5FCFDF;
    --ion-color-tertiary-rgb: 95, 207, 223;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #54b6c4;
    --ion-color-tertiary-tint: #6fd4e2;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --echt-petrol-80: #337f8d;
    --petrol-100: #005f71;
    --petrol-80: #00768c;
    --petrol-60: #378a99;
    --petrol-50: #669faa;
    --petrol-20: #99bfc6;
    --petrol-10: #ccdfe3;
    --petrol-5: #ecf1f5;
    --aquamarin-100: #5fcfdf;
    --aquamarin-90: #7dd8e5;
    --aquamarin-80: #80e4f2;
    --aquamarin-60: #a8eff7;
    --aquamarin-40: #c5eef4;
    --aquamarin-20: #dff6fa;
    --aquamarin-10: #f2fdff;
    --nightblue-100: #15224f;
    --nightblue-90: #414c71;
    --nightblue-80: #414c71;
    --nightblue-60: #69718e;
    --nightblue-50: #abafbf;
    --nightblue-20: #d9dce3;
    --nightblue-10: #f6f6f8;
    --skyblue-100: #def0f4;
    --violet-120: #634675;
    --violet-100: #8f66a9;
    --violet-80: #a472c4;
    --violet-60: #be91db;
    --violet-40: #d1b6e3;
    --violet-20: #e9daf2;
    --violet-10: #faf2ff;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #d9dce3;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}
